/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mod_blog_page {
  background: #f9f6ef;
  padding: 8rem;
  overflow: hidden;
}
@media (max-width: 991px) {
  .mod_blog_page {
    padding: 2rem;
  }
}
.blogBox {
  width: 100%;
  background: #fff;
  margin-bottom: 6rem;
  transition: all 0.15s;
  padding: 1rem;
  border-radius: 2rem;
}
@media (max-width: 767px) {
  .blogBox {
    border: none;
    border-radius: 0;
  }
}
.blogBox:hover {
  transform: scale(1.1);
}
.blogBox > .top {
  height: 22.5rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-size: cover;
  background-position: center;
}
.blogBox > .title {
  text-align: left;
  font-size: 2.4rem;
  font-weight: 600;
  color: #745a9e;
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}
.blogBox .metadataFrame {
  display: inline-flex;
  font-size: 1.2rem;
  color: #6c6256;
}
.blogBox .metadataFrame > .authorFrame {
  display: inline-flex;
}
.blogBox .metadataFrame > .authorFrame .pictureCircle {
  margin: auto;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  opacity: 1;
  transition: background-image 0.3s;
}
.blogBox .metadataFrame > .authorFrame .authorName {
  padding: 1rem;
}
.blogBox .metadataFrame > .date {
  padding: 1rem;
}
