/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fullWidthSection.white > .sectionRow {
  background: #fff;
}
.fullWidthSection.white > .sectionRow:nth-child(odd) {
  background: #fff;
}
.fullWidthSection > .sectionRow {
  background: #fff;
  padding: 50px 0 80px;
  position: relative;
}
.fullWidthSection > .sectionRow:nth-child(odd) {
  background: #fff;
}
.fullWidthSection > .sectionRow > .fixedWidthWrapper {
  position: relative;
  width: 1084px;
  margin: 0 auto;
}
@media (max-width: 1084px) {
  .fullWidthSection > .sectionRow > .fixedWidthWrapper {
    width: 100%;
  }
}
.fullWidthSection > .sectionRow > .fixedWidthWrapper > div > .title,
.fullWidthSection > .sectionRow > .fixedWidthWrapper > div > div > .title {
  font-size: 3em;
  font-weight: 600;
  color: #745a9e;
  font-family: 'Zona Pro', 'Verdana';
  text-align: center;
  margin-top: 60px;
  margin-bottom: 20px;
  margin-top: 0;
}
@media (max-width: 991px) {
  .fullWidthSection > .sectionRow > .fixedWidthWrapper > div > .title,
  .fullWidthSection > .sectionRow > .fixedWidthWrapper > div > div > .title {
    font-size: 2.2em;
    margin-left: 1rem;
  }
}
@media (max-width: 991px) {
  .fullWidthSection > .sectionRow > .fixedWidthWrapper > div > .title,
  .fullWidthSection > .sectionRow > .fixedWidthWrapper > div > div > .title {
    margin-left: 10px;
  }
}
.fullWidthSection > .sectionRow > .fixedWidthWrapper > div > .subtitle,
.fullWidthSection > .sectionRow > .fixedWidthWrapper > div > div > .subtitle {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 4rem;
  color: #745a9e;
  font-size: 24px;
  text-align: center;
}
@media (max-width: 991px) {
  .fullWidthSection > .sectionRow > .fixedWidthWrapper > div > .subtitle,
  .fullWidthSection > .sectionRow > .fixedWidthWrapper > div > div > .subtitle {
    font-size: 1.6rem;
  }
}
.fullWidthSection > .sectionRow > .arrow {
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -53px;
}
.fullWidthSection > .sectionRow.fullWidthWrapper {
  padding: 0;
}
.fullWidthSection.showArrows > .sectionRow:not(:first-child) {
  padding-top: 100px;
}
.fullWidthSection > .sectionFooter {
  margin: 80px auto 0;
  max-width: 1200px;
  width: 100%;
}
