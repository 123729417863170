/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.topPageBanner {
  background: #633c6f;
  position: relative;
}
.topPageBanner > .background {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -790px;
}
.topPageBanner > .fixedWidthWrapper {
  padding: 50px 0 20px;
  position: relative;
  width: 1084px;
  margin: 0 auto;
  z-index: 100;
}
@media (max-width: 1084px) {
  .topPageBanner > .fixedWidthWrapper {
    width: 100%;
  }
}
.topPageBanner > .fixedWidthWrapper > .leftImage {
  float: left;
  margin: -30px 30px 0 -20px;
  max-height: 173px;
  width: auto;
}
@media (max-width: 991px) {
  .topPageBanner > .fixedWidthWrapper > .leftImage {
    display: none;
  }
}
.topPageBanner > .fixedWidthWrapper > .title {
  font-size: 3em;
  font-weight: 600;
  color: #745a9e;
  font-family: 'Zona Pro', 'Verdana';
  text-align: center;
  color: #fff;
}
@media (max-width: 991px) {
  .topPageBanner > .fixedWidthWrapper > .title {
    font-size: 2.2em;
    margin-left: 1rem;
  }
}
.topPageBanner > .fixedWidthWrapper > .subtitle {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 4rem;
  color: #745a9e;
  color: #ffcbff;
}
@media (max-width: 991px) {
  .topPageBanner > .fixedWidthWrapper > .subtitle {
    font-size: 1.6rem;
  }
}
.topPageBanner.blue {
  background: #0084bb;
}
.topPageBanner.blue > .fixedWidthWrapper > .subtitle {
  color: #9be2ff;
}
